/* ===== Buttons Css ===== */
.bg{
  background-color: rgb(254, 174, 0)  !important;
}
.call-action-one .call-action-content .call-action-btn .primary-btn {
    background: var(--primary);
    color: var(--white);
    box-shadow: var(--shadow-2);
  
  }
  .call-action-one .call-action-content .call-action-btn .active.primary-btn, .call-action-one .call-action-content .call-action-btn .primary-btn:hover, .call-action-one .call-action-content .call-action-btn .primary-btn:focus {
    background: var(--primary-dark);
    color: var(--white);
    box-shadow: var(--shadow-4);
 
  }
  .call-action-one .call-action-content .call-action-btn .deactive.primary-btn {
    background: var(--gray-4);
    color: var(--dark-3);
    pointer-events: none;
  }
  
  /*===== call action one =====*/
  .call-action-one {
    background-color: var(--light-2);
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .call-action-one .call-action-content .call-action-text {
    margin-top: 50px;
  }
  .call-action-one .call-action-content .call-action-text .action-title {
    font-weight: 700;
    color: var(--black);
    font-family: 'Prata', serif;
  }
  .call-action-one .call-action-content .call-action-text .text-lg {
    color: var(--dark-3);
    margin-top: 16px;
  }
  .call-action-one .call-action-content .call-action-btn {
    margin-top: 50px;
  }
  .box{
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .form-box{
    padding: 5px;
    padding-left: 70px;
    padding-right: 20px;
    border-radius: 35px;
    background: #ececec;
    box-shadow: 10px 10px 15px #c7c4c4, -10px -10px -15px #fff;
  }
  .form-box input {
    font-size: 18px;
    color: #000;
    padding: 15px 10px;
    border: none;
    outline: none;
    background: #ececec;
  }
  .form-box button{
    color: #fff;
    padding: 8px;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 20px;
    border: none;
    border-radius: 35px;
    background: #4769d8;
    cursor: pointer;
    transition: .3s;
  }
  .form-box button:hover{
    background: #2e54d3;
  }