/*===========================
    clients-01 css 
===========================*/
.head{
    font-family: 'Prata', serif;
  }
  .head::after{
    content: '';
    width: 0;
    height: 4px;
    background-color: var(--primary);
    margin: 10px  auto;
    display: block;
    width: 18%;
    border-radius: 1000px 1000px 1000px 1000px;
  }
.client-logo-area .section-title-two {
    text-align: center;
    max-width: 550px;
    margin: auto;
    margin-bottom: 50px;
    position: relative;
    z-index: 5;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .client-logo-area .section-title-two {
      margin-bottom: 45px;
    }
  }
  @media (max-width: 767px) {
    .client-logo-area .section-title-two {
      margin-bottom: 35px;
    }
  }
  .client-logo-area .section-title-two span {
    text-transform: capitalize;
    color: var(--white);
    background: var(--primary);
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 15px;
    border-radius: 4px;
  }
  .client-logo-area .section-title-two h2 {
    margin-bottom: 15px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .client-logo-area .section-title-two h2 {
      font-size: 2rem;
      line-height: 2.8rem;
    }
  }
  @media (max-width: 767px) {
    .client-logo-area .section-title-two h2 {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }
  }
  .client-logo-area .section-title-two p {
    color: var(--dark-3);
  }
  
  /*===== Client Logo One =====*/
  .client-logo-one {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .client-logo-one .single-client {
    margin-top: 30px;
  }