/*===========================
  teams css 
===========================*/
.heading{
    font-family: 'Prata', serif;
    font-weight: 700;
}
.heading::after{
  content: '';
  width: 0;
  height: 4px;
  background-color: var(--primary);
  margin: 10px  auto;
  display: block;
  width: 6%;
  border-radius: 1000px 1000px 1000px 1000px;
}
.team-style-one {
    position: relative;
    margin-top: 30px;
    box-shadow: var(--shadow-2);
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Prata', serif;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
  }
  .team-style-one:hover {
    box-shadow: var(--shadow-4);
  }
  .team-style-one .team-image img {
    width: 100%;
  }
  .team-style-one .team-content {
    padding: 20px 15px;
    width: 100%;
    background-color: var(--white);
  }
  .team-style-one .team-content .name {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: var(--black);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-style-one .team-content .name {
      font-size: 20px;
    }
  }
  .team-style-one .team-content .sub-title {
    font-size: 15px;
    line-height: 24px;
    color: var(--dark-3);
    margin-top: 2px;
    display: block;
  }
  .team-style-one .team-content .social {
    margin-top: 12px;
  }
  .team-style-one .team-content .social li {
    display: inline-block;
    margin: 0 4px;
  }
  .team-style-one .team-content .social li a {
    font-size: 16px;
    color: var(--gray-1);
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    border: 1px solid var(--gray-4);
    border-radius: 50%;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
  }
  .team-style-one .team-content .social li a:hover {
    background-color: var(--primary);
    color: var(--white);
    border-color: transparent;
  }