/*
.services-title{
    color: blue;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

.services-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}
@media only screen and (max-width: 576px){
    .services-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
}
@media only screen and (min-width: 577px) and (max-width: 992px) {
    .services-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
} 




*/
.heading{
    font-family: 'Prata', serif;
    font-weight: 700;
}
.heading::after{
  content: '';
  width: 0;
  height: 4px;
  background-color: var(--primary);
  margin: 10px  auto;
  display: block;
  width: 6%;
  border-radius: 1000px 1000px 1000px 1000px;
}